import React, {memo} from 'react';
import {Trans} from 'gatsby-plugin-react-i18next';
import PageWrapper from '../containers/PageWrapper';
import ContactForm from '../modules/ContactForm';

import Section from '../components/Section';
import UnderlineHeading from '../components/UnderlineHeading';
import SectionDescription from '../components/SectionDescription';

const Contact = () => (
  <PageWrapper
    title="Contact"
    description="<Trans>Contact Us Hint</Trans>"
    urlPath="contact"
    keywords={['coderylabs', 'contact form', 'contact']}
  >
    <Section>
      <UnderlineHeading><Trans>Contact Us</Trans></UnderlineHeading>
      <SectionDescription>
          <Trans>Contact us desc</Trans>
      </SectionDescription>
    </Section>

    <Section theme="dark">
      <SectionDescription>
        <Trans>Contact Us Hint</Trans>
      </SectionDescription>
      <ContactForm showTopics/>
    </Section>
  </PageWrapper>
);

export default memo(Contact);
